import React from 'react'
import * as THREE from 'three'
import DoorHandle from '../doorHandle/DoorHandle.js'
import Fittings from '../fittings/Fittings.js'
import Glass from './Glass.js'

export default function Door({ size, geometry, model, ...args }) {
    return (
        <group {...args}>
            <Glass geometry={geometry}/>
            <DoorHandle size={size}/>
            <Fittings size={size}/>
        </group>
    )
}
