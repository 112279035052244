import React, {useContext, useEffect} from 'react'
import {OptionsContext} from '../../../App.js'
import {configName} from '../../../helpers/constants.js'
import {getRendererOptions} from '../../../helpers/functions.js'
import {Icon} from '../../icon'

export default function Footer() {
    const { config, tabs, setTabs, priceTable, totalPrice, setTotalPrice, ref } = useContext( OptionsContext )

    function handlerClickTab( tab ) {
        const newTabs = tabs.map(t => {
            t.checked = t.id === tab.id
            return t
        })
        setTabs( newTabs )
    }

    /**
     * Отримати та виставити прайси на кожен тип деревини при зміні розміру
     * @param model Object, модель
     * @param width ширина моделі
     * @param depth глибина моделі
     * @param wood Object, айтем - тип деревини
     * @returns {*|number}
     */
    function getPrice(model, width, depth, wood) {
        const typeWood = wood.name === 'fichte' ? 'fichte' : wood.name === 'zirbe' ? 'zirbe': 'rest'

        const table = priceTable[ model ][ typeWood ]

        if (!table) return 0

        const indexCol = table[0].findIndex( row => row === String( width ) )
        const row = table.find( col => col[0] === String( depth ) )
        const price = row?.[ indexCol ]

        if (price) return price
        else return 0
    }

    /**
     * Set prices depending on the size of the model
     */
    useEffect(() => {
        const model = config[configName.model].value.optionsSelected[0].name
        const width = Number( (config[configName.size].value.width * 100).toFixed(2) )
        const depth = Number( (config[configName.size].value.depth * 100).toFixed(2) )

        config[configName.typeWood].value.options.forEach(wood => {
            wood.price = 0
        })
        config[configName.typeWood2].value.options.forEach(wood => {
            wood.price = getPrice(model, width, depth, wood)
        })

    }, [
        config[configName.model].value,
        config[configName.size].value,
    ])

    /** Price: Set Total Price */
    useEffect(() => {
        let totalPrice = 0

        Object.keys(config).forEach(key => {
            const { value } = config[key]

            if (value.name === configName.model) {

                // Виключення опції від сумування значення ціни - configName.model

            } else {

                // Підсумування цін selected multiple options
                if (value.name === configName.lamp) {

                    value.optionsSelected.forEach(option => {
                        totalPrice += Number(option.price)
                    })

                } else {

                    const selectOption = getRendererOptions({config, groupName: value.name, selected: true})

                    if (selectOption && selectOption.price) {
                        totalPrice += Number(selectOption.price)
                    }

                }

            }
        })

        console.log( totalPrice )
        setTotalPrice( totalPrice.toFixed(2) )

    }, Object.keys(config).map(key => config[key].value))

    return (
        <>
            <div className="options_footer__total_price">
                <div className="title">Preis</div>
                <div className="price">
                    <span className="notification_taxes">Betrag inkl. 19 MwSt</span>
                    <span className="amount">{ totalPrice }<span className="currency_symbol">€</span></span>
                </div>
            </div>
            <div className="options_footer__tabs">
                <ul className="options_footer__tabs_list">
                    {
                        tabs.map( tab => {
                            return (
                                <li className={ `options_footer__tab${ tab.checked ? ' active' : ''}` } key={ tab.id }>
                                    <div className="options_footer__tab_in" onClick={ handlerClickTab.bind( this, tab ) }>
                                        <div className="icon_wrap">
                                            <Icon icon={ `tab_${ tab.id }` } className="icon" />
                                        </div>
                                        <div className="title">{ tab.title }</div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>

            <div className="options_footer__button_wrap options_footer__button_wrap--desktop">
                <button ref={ref.buttonSubmit} className="form_button_summary" type="submit">Zusammenfassung</button>
            </div>

            <div className="options_footer__button_wrap options_footer__button_wrap--mobile">
                <button ref={ref.buttonSubmitMob} className="form_button_summary" type="submit">Gesamt</button>
                <div className="price_wrapper">
                    <div className="title">Preis</div>
                    <div className="price">
                        <span className="notification_taxes">Betrag inkl. 19 MwSt</span>
                        <span className="amount">{ totalPrice }<span className="currency_symbol">€</span></span>
                    </div>
                </div>
            </div>
        </>
    )
}