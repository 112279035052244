import React, {useContext, useEffect, useMemo, useState} from 'react'
import * as THREE from 'three'
import {useFrame, useThree} from '@react-three/fiber'
import {OptionsContext} from '../../App.js'
import {configName} from '../../helpers/constants.js'
import {useControls} from "leva";

export default function Screenshot() {
    const { config, screenshot, ref } = useContext( OptionsContext )
    const tree = useThree()
    // const { camera } = tree

    // TODO: for test
    // const leva = useControls({
    //     x: { value: 0, min: -10, max: 10, step: .1 },
    //     y: { value: 5, min: -10, max: 10, step: .1 },
    //     z: { value: 0, min: -10, max: 10, step: .1 },
    // })
    //
    // useFrame((rootState, number, xrfFrame ) => {
    //     ref.controls.current.object.position.set(leva.x, leva.y, leva.z);
    // })

    screenshot.create = callback => {
        const images = []
        const model = config[configName.model].value.optionsSelected[0]
        const ceiling = ref[model.name].current.children.find(elem => elem.name === 'ceiling')

        // console.log(  )

        const { background: sceneBackground } =  tree.scene
        const { x: cameraPosX, y: cameraPosY, z: cameraPosZ } = ref.controls.current.object.position

        tree.scene.background = new THREE.Color('#ffffff')

        const positions = [
            // define camera positions of all sides as vector3, {x:0,y:0,z:0}
            {x: - 6, y: 2.5, z: 3.9},
            {x: - 6, y: 0, z: 0},
            {x: 0, y: 6, z: 0},
        ]

        const delayBetweenScreenshots = 0;

        const captureScreenshot = async (position, index) => {
            if (index === 2) ceiling.visible = false
            ref.controls.current.object.position.set(position.x, position.y, position.z);
            ref.controls.current?.update();
            await new Promise((resolve) => setTimeout(resolve, delayBetweenScreenshots));
            const screenshotDataUrl = ref.canvas.current.toDataURL('image/jpeg'); // image/png - default
            if (index === 2) ceiling.visible = true
            images.push(screenshotDataUrl)
            // const a = document.createElement('a');
            // a.href = screenshotDataUrl;
            // a.download = `${index}.jpg`;
            // a.click();
        };

        const captureScreenshotsSequentially = async () => {
            await new Promise((resolve) => setTimeout(resolve, 500));
            for (let i = 0; i < positions.length; i++) {
                await captureScreenshot(positions[i], i);
            }
        };

        captureScreenshotsSequentially().then(async () => {
            await new Promise((resolve) => setTimeout(resolve, delayBetweenScreenshots));
            ref.controls.current.object.position.set(cameraPosX, cameraPosY, cameraPosZ);
            tree.scene.background = sceneBackground
            ref.controls.current?.update();
            callback(images)
        })
    }

    return null
}