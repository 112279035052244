import React, {useContext, useMemo} from 'react'
import {Base, Geometry, Subtraction} from '@react-three/csg'
import VerticalBars from './VerticalBars.js'
import Watermark from './Watermark.js'
import Door from './Door.js'
import Glass from './Glass.js'
import * as THREE from 'three'
import {getParamWall} from '../../helpers/functions.js'
import {OptionsContext} from '../../App.js'
import {configName} from '../../helpers/constants.js'

export default function Walls({size, texture}) {
    const { config } = useContext( OptionsContext )
    const model = config[configName.model].value.optionsSelected[0]

    const params = useMemo(() => {

        const params = {
            wall: getParamWall(model.name, size),
            geometry: {
                tri: new THREE.CylinderGeometry(size.model.wallThickness / 2, size.model.wallThickness / 2, size.model.height, 3),
                wallX: new THREE.BoxGeometry(size.model.width, size.model.height, size.model.wallThickness),
                wallZ: new THREE.BoxGeometry(size.model.depth, size.model.height, size.model.wallThickness),
            },
            venus: {doorPosition: null, w1Position: null, w2Position: null},
            kristall: {window: {width: null, height: null, offset: null}},
            morena: {positionFrontOpening: null},
        }

        if (model.name === 'venus') {
            const doorPosX = (size.model.width - size.door.width) / 2 - size.model.wallThickness
            const windowPosY = size.door.height - size.model.height / 2

            if (size.model.width < 1.7) {
                const windowPosX = ((size.model.width - size.window.w1.width) / 2 - size.model.wallThickness) - .0001

                params.venus.doorPosition = [
                    size.glassElem.side === 'R' ? doorPosX : - doorPosX,
                    - (size.model.height - size.door.height) / 2 - .0001,
                    0
                ]
                params.venus.w1Position = [
                    size.glassElem.side === 'R' ? - windowPosX : windowPosX,
                    (windowPosY - size.window.w1.height / 2) - .0001,
                    0
                ]

            } else {
                const window1PosX = - ((size.model.width - size.window.w2.width) / 2 - size.model.wallThickness) + .0001
                const window2PosX = (size.model.width - size.window.w1.width) / 2 - size.model.wallThickness

                params.venus.doorPosition = [
                    0,
                    - (size.model.height - size.door.height) / 2 - .0001,
                    0
                ]
                params.venus.w1Position = [
                    size.glassElem.side === 'R' ? window1PosX : - window1PosX,
                    (windowPosY - size.window.w1.height / 2) - .0001,
                    0
                ]
                params.venus.w2Position = [
                    size.glassElem.side === 'R' ? window2PosX : - window2PosX,
                    windowPosY - size.window.w2.height / 2,
                    0
                ]
            }
        }

        if (model.name === 'kristall') {
            const widthOutdoor = size.model.width - size.model.wallThickness * 2
            const widthWindow = (widthOutdoor - size.door.width) / 2
            params.kristall.window.width = widthWindow
            params.kristall.window.height = size.door.height
            params.kristall.window.offset = (widthOutdoor - widthWindow) / 2
        }

        if (model.name === 'morena') {
            params.morena.widthFrontOpening = size.door.width + size.window.w1.width

            // ---------- Front ----------

            params.morena.positionFrontOpening = [
                size.glassElem.side === 'R'
                    ? params.morena.widthFrontOpening / 2 - size.model.width / 2
                    : size.model.width / 2 - params.morena.widthFrontOpening / 2,
                size.door.height / 2 - size.model.height / 2 - .0001,
                0
            ]

            params.morena.positionDoorX = size.glassElem.side === 'R'
                ? params.morena.widthFrontOpening / 2 - size.door.width / 2
                : - (params.morena.widthFrontOpening / 2 - size.door.width / 2)

            params.morena.positionFrontWindowX = size.glassElem.side === 'R'
                ? - ((params.morena.widthFrontOpening - size.window.w1.widthGlass) / 2 - (size.window.w1.width - size.window.w1.widthGlass))
                : (params.morena.widthFrontOpening - size.window.w1.widthGlass) / 2 - (size.window.w1.width - size.window.w1.widthGlass)

            // ---------- Right ----------

            params.morena.positionRightOpening = [
                size.model.depth / 2 - size.window.w2.width / 2,
                size.door.height / 2 - size.model.height / 2 - .0001,
                0
            ]

            params.morena.positionRightWindowX = size.window.w2.widthGlass / 2 - size.window.w2.width / 2

            // ---------- Left ----------

            params.morena.positionLeftOpening = [
                - (size.model.depth / 2 - size.window.w2.width / 2),
                size.door.height / 2 - size.model.height / 2 - .0001,
                0
            ]

            params.morena.positionLeftWindowX = - (size.window.w2.widthGlass / 2 - size.window.w2.width / 2)

        }

        return params

    }, [size] )

    return (
        <>
            <Wall wall="front" size={size} texture={texture} model={model} params={params} textureKey={'w'}/>
            <Wall wall="right" size={size} texture={texture} model={model} params={params} textureKey={'d'}/>
            <Wall wall="left" size={size} texture={texture} model={model} params={params} textureKey={'d'}/>
            <Wall wall="back" size={size} texture={texture} model={model} params={params} textureKey={'w'}/>
        </>
    )
}

function Wall({wall, size, texture, model, params, textureKey}) {

    return (
        <group name={wall} position={params.wall[wall].position} rotation={params.wall[wall].rotation}>

            <VerticalBars size={size} wall={wall} texture={texture}/>

            <Watermark size={size} wall={wall} />

            <mesh>
                <Geometry useGroups>
                    <Base geometry={wall === 'back' || wall === 'front' ? params.geometry.wallX : params.geometry.wallZ}>
                        <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                        <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                        <meshStandardMaterial attach="material-4" map={texture.in.wall[textureKey]}/>
                        <meshStandardMaterial attach="material-5" map={texture.out.wall[textureKey]}/>
                    </Base>
                    <Subtraction geometry={params.geometry.tri} scale={[2.5, 1, 1.425]} rotation={[0, -Math.PI / 4, 0]} position={[- params.wall[wall].subtractionPositionX, 0, 0]}>
                        <meshStandardMaterial map={texture.in.bar.ver}/>
                    </Subtraction>
                    <Subtraction geometry={params.geometry.tri} scale={[2.5, 1, 1.425]} rotation={[0, Math.PI / 4, 0]} position={[params.wall[wall].subtractionPositionX, 0, 0]}>
                        <meshStandardMaterial map={texture.in.bar.ver}/>
                    </Subtraction>

                    {model.name === 'venus' && wall === 'front' && (
                        <>
                            <group name="doorway" position={params.venus.doorPosition}>
                                <Subtraction>
                                    <boxGeometry args={[
                                        size.door.width,
                                        size.door.height,
                                        size.model.wallThickness]} />
                                    <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                                    <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                                    <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                                    <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                                    <meshStandardMaterial attach="material-4"/>
                                    <meshStandardMaterial attach="material-5"/>
                                </Subtraction>
                                <Door
                                    size={size}
                                    geometry={new THREE.BoxGeometry(
                                        size.door.width - size.door.clearance * 2,
                                        size.door.height - size.door.clearance * 2,
                                        size.door.depth)
                                    }
                                    position={[
                                        0,
                                        0,
                                        -(size.model.wallThickness - size.door.depth)/2 + (size.window.depth)/2
                                    ]}
                                />
                            </group>

                            {
                                (size.glassElem.name === '1glas' || size.glassElem.name === '2glas') && (
                                    <group name="window_1" position={params.venus.w1Position}>
                                        <Subtraction>
                                            <boxGeometry args={[
                                                size.window.w1.width,
                                                size.window.w1.height,
                                                size.model.wallThickness
                                            ]}/>
                                            <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-4"/>
                                            <meshStandardMaterial attach="material-5"/>
                                        </Subtraction>
                                        <Glass geometry={new THREE.BoxGeometry(
                                            size.window.w1.width,
                                            size.window.w1.height,
                                            size.model.wallThickness - .02)
                                        }/>
                                    </group>
                                )
                            }

                            {
                                size.glassElem.name === '2glas' && size.model.width >= 1.7 && (
                                    <group name="window_2" position={params.venus.w2Position}>
                                        <Subtraction>
                                            <boxGeometry args={[
                                                size.window.w2.width,
                                                size.window.w2.height,
                                                size.model.wallThickness]}/>
                                            <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-4"/>
                                            <meshStandardMaterial attach="material-5"/>
                                        </Subtraction>
                                        <Glass geometry={new THREE.BoxGeometry(
                                            size.window.w2.width,
                                            size.window.w2.height,
                                            size.model.wallThickness - .02)}/>
                                    </group>
                                )
                            }

                            {
                                size.glassElem.name === 'bodentiefes' && (
                                    <group name="bodentiefes_fenster" position={params.venus.w1Position}>
                                        <Subtraction>
                                            <boxGeometry args={[
                                                size.window.w1.width,
                                                size.window.w1.height,
                                                size.model.wallThickness]}/>
                                            <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                                            <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                                            <meshStandardMaterial attach="material-4"/>
                                            <meshStandardMaterial attach="material-5"/>
                                        </Subtraction>
                                        <Glass
                                            geometry={new THREE.BoxGeometry(
                                                size.window.w1.width,
                                                size.window.w1.height,
                                                size.door.depth)}
                                            position-z={-.03}
                                        />
                                    </group>
                                )
                            }
                        </>
                    )}

                    {model.name === 'kristall' && wall === 'front' && (
                        <group name="doorway" position={[0, - (size.model.height - size.door.height) / 2 - .0001, - .005]}>
                            <Subtraction>
                                <boxGeometry args={[size.model.width - size.model.wallThickness * 2, size.door.height - .0001, size.model.wallThickness + .01]}/>
                                <meshStandardMaterial map={texture.in.bar.hor}/>
                            </Subtraction>

                            <Door
                                size={size}
                                model={'kristal'}
                                geometry={new THREE.BoxGeometry(
                                    size.door.width - size.door.clearance * 2,
                                    size.door.height - size.door.clearance * 2,
                                    size.door.depth)}
                            />

                            {/* Window LEFT */}
                            <Glass geometry={new THREE.BoxGeometry(
                                params.kristall.window.width,
                                size.door.height,
                                size.door.depth)}
                                   position={[params.kristall.window.offset, 0, 0]}
                            />

                            {/* Window RIGHT */}
                            <Glass geometry={new THREE.BoxGeometry(
                                params.kristall.window.width,
                                size.door.height,
                                size.door.depth)}
                                   position={[- params.kristall.window.offset, 0, 0]}/>

                        </group>
                    )}

                    {model.name === 'morena' && (
                        <>
                            {wall === 'front' && (
                                <group name="doorway" position={params.morena.positionFrontOpening}>
                                    <Subtraction>
                                        <boxGeometry args={[
                                            params.morena.widthFrontOpening,
                                            size.door.height,
                                            size.model.wallThickness]} />
                                        <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                                        <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                                        <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                                        <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                                        <meshStandardMaterial attach="material-4"/>
                                        <meshStandardMaterial attach="material-5"/>
                                    </Subtraction>
                                    <Door
                                        size={size}
                                        geometry={new THREE.BoxGeometry(size.door.width - size.door.clearance * 2, size.door.height - size.door.clearance * 2, size.door.depth)}
                                        position-x={params.morena.positionDoorX}
                                    />
                                    <Glass
                                        geometry={new THREE.BoxGeometry(
                                            size.window.w1.widthGlass,
                                            size.window.w1.height,
                                            size.door.depth)}
                                        position-x={params.morena.positionFrontWindowX}
                                    />
                                </group>
                            )}

                            {wall === 'right' && size.glassElem.side === 'R' && (
                                <group position={params.morena.positionRightOpening}>
                                    <Subtraction>
                                        <boxGeometry args={[
                                            size.window.w2.width,
                                            size.door.height,
                                            size.model.wallThickness]} />
                                        <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                                        <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                                        <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                                        <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                                        <meshStandardMaterial attach="material-4"/>
                                        <meshStandardMaterial attach="material-5"/>
                                    </Subtraction>
                                    <Glass
                                        geometry={new THREE.BoxGeometry(
                                            size.window.w2.widthGlass,
                                            size.window.w2.height,
                                            size.door.depth)}
                                        position-x={params.morena.positionRightWindowX}
                                    />
                                </group>
                            )}

                            {wall === 'left' && size.glassElem.side === 'L' && (
                                <group position={params.morena.positionLeftOpening}>
                                    <Subtraction>
                                        <boxGeometry args={[size.window.w2.width, size.door.height, size.model.wallThickness]} />
                                        <meshStandardMaterial attach="material-0" map={texture.in.bar.ver}/>
                                        <meshStandardMaterial attach="material-1" map={texture.in.bar.ver}/>
                                        <meshStandardMaterial attach="material-2" map={texture.in.bar.hor}/>
                                        <meshStandardMaterial attach="material-3" map={texture.in.bar.hor}/>
                                        <meshStandardMaterial attach="material-4"/>
                                        <meshStandardMaterial attach="material-5"/>
                                    </Subtraction>
                                    <Glass
                                        geometry={new THREE.BoxGeometry(
                                            size.window.w2.widthGlass,
                                            size.window.w2.height,
                                            size.door.depth)}
                                        position-x={params.morena.positionLeftWindowX}
                                    />
                                </group>
                            )}
                        </>
                    )}

                </Geometry>
            </mesh>
        </group>
    )
}
