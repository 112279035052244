import {useContext, useEffect, useMemo, useState} from 'react'
import {OptionsContext} from '../../App.js'
import {configName} from "../../helpers/constants.js"

import {Model as Rund_Edelstahloptik_90} from './Doppelturband_B3305_C04.jsx'
import {Model as Rund_Edelstahloptik_180} from './Doppelturband_B3305_C04_open.jsx'

import {Model as Eckig_Edelstahloptik_90} from './Gewerbliches_Tuerband_scaled_e.jsx'
import {Model as Eckig_Edelstahloptik_180} from './Gewerbliches_Tuerband_scaled_e_mirror.jsx'

import {Model as Eckig_Edelstahl_90} from './8180_GW.jsx'
import {Model as Eckig_Edelstahl_180} from './8180_GW_open_mirror.jsx'
import {Model as Eckig_Edelstahl_180_x2} from './8180_GW_open.jsx'

import {Model as Eckig_Schwarz_90} from './Ding1_Kopie.jsx'
import {Model as Eckig_Schwarz_180} from './Ding1_Kopie_open.jsx'
import {Model as Eckig_Schwarz_180_x2} from './Ding1_Kopie_open_mirror.jsx'

import {Model as Rund_Schwarz_90} from './Tuerband_schwarz_B3308_S02_scaled_e.jsx'
import {Model as Rund_Schwarz_180} from './Tuerband_schwarz_B3308_S02_scaled_e_mirror.jsx'

export default function Fittings({ size }) {
    const { config } = useContext( OptionsContext )

    const [sideRight, setSideRight] = useState(size.glassElem.side === 'R')

    /** Side - State */
    useEffect(() => {
        if (sideRight !== (size.glassElem.side === 'R')) {
            setSideRight(size.glassElem.side === 'R')
        }
    }, [size])

    return useMemo(() => {
        const position = [
            sideRight ? 0 : -size.door.width,
            sideRight ? 0 : .1,
            0
        ]
        const rotationItemZ = sideRight ? 0 : Math.PI

        if (config[configName.model].value.optionsSelected[0].name === 'kristall') {
            return [
                <group position={position}>
                    <Rund_Edelstahloptik_180 scale={.04} position={[sideRight ? size.door.width/2 - .004 : size.door.width/2 + .005, size.door.height/3, -.007]} rotation={[0, 0, rotationItemZ]}/>
                    <Rund_Edelstahloptik_180 scale={.04} position={[sideRight ? size.door.width/2 - .004 : size.door.width/2 + .005, -size.door.height/3 - .095, -.007]} rotation={[0, 0, rotationItemZ]}/>
                </group>,
                <group position={position}>
                    <Eckig_Edelstahloptik_180 scale={.04} position={[sideRight ? size.door.width/2 : size.door.width/2 + .02 - .01, size.door.height/3, -.014]} rotation={[0, 0, rotationItemZ]}/>
                    <Eckig_Edelstahloptik_180 scale={.04} position={[sideRight ? size.door.width/2 : size.door.width/2 + .02 - .01, -size.door.height/3 - .095, -.014]} rotation={[0, 0, rotationItemZ]}/>
                </group>,
                <group position={position}>
                    <Eckig_Edelstahl_180 scale={.04} position={[sideRight ? size.door.width/2 + .003 : size.door.width/2 - .003, size.door.height/3, .032]} rotation={[0, Math.PI, rotationItemZ]}/>
                    <Eckig_Edelstahl_180 scale={.04} position={[sideRight ? size.door.width/2 + .003 : size.door.width/2 - .003, -size.door.height/3 - .095, .032]} rotation={[0, Math.PI, rotationItemZ]}/>
                </group>,
                <group position={position}>
                    <Eckig_Schwarz_180 scale={.04} position={[sideRight ? size.door.width/2 + .003 : size.door.width/2 - .001, size.door.height/3, .032]} rotation={[0, Math.PI, rotationItemZ]}/>
                    <Eckig_Schwarz_180 scale={.04} position={[sideRight ? size.door.width/2 + .003 : size.door.width/2 - .001, -size.door.height/3 - .095, .032]} rotation={[0, Math.PI, rotationItemZ]}/>
                </group>,
                <group position={position}>
                    <Rund_Schwarz_180 scale={.04} position={[sideRight ? size.door.width/2 - .01 : size.door.width/2 + .01, size.door.height/3, -.007]} rotation={[0, 0, rotationItemZ]}/>
                    <Rund_Schwarz_180 scale={.04} position={[sideRight ? size.door.width/2 - .01 : size.door.width/2 + .01, -size.door.height/3 - .095, -.007]} rotation={[0, 0, rotationItemZ]}/>
                </group>,
            ][config[configName.fittings].value.optionsSelected[0].id]
        }

        return [
            <group position={position}>
                <Rund_Edelstahloptik_90 scale={.04} position={[sideRight ? size.door.width/2 - .004 : size.door.width/2 + .005, size.door.height/3, -.007]} rotation={[0, 0, rotationItemZ]}/>
                <Rund_Edelstahloptik_90 scale={.04} position={[sideRight ? size.door.width/2 - .004 : size.door.width/2 + .005, -size.door.height/3 - .095, -.007]} rotation={[0, 0, rotationItemZ]}/>
            </group>,
            <group position={position}>
                <Eckig_Edelstahloptik_90 scale={.04} position={[sideRight ? size.door.width/2 : size.door.width/2 + .02 - .01, size.door.height/3, -.014]} rotation={[0, 0, rotationItemZ]}/>
                <Eckig_Edelstahloptik_90 scale={.04} position={[sideRight ? size.door.width/2 : size.door.width/2 + .02 - .01, -size.door.height/3 - .095, -.014]} rotation={[0, 0, rotationItemZ]}/>
            </group>,
            <group position={position}>
                <Eckig_Edelstahl_90 scale={.04} position={[sideRight ? size.door.width/2 + .003 : size.door.width/2 - .003, size.door.height/3, .032]} rotation={[0, Math.PI, rotationItemZ]}/>
                <Eckig_Edelstahl_90 scale={.04} position={[sideRight ? size.door.width/2 + .003 : size.door.width/2 - .003, -size.door.height/3 - .095, .032]} rotation={[0, Math.PI, rotationItemZ]}/>
            </group>,
            <group position={position}>
                <Eckig_Schwarz_90 scale={.04} position={[sideRight ? size.door.width/2 + .003 : size.door.width/2 - .001, size.door.height/3, .032]} rotation={[0, Math.PI, rotationItemZ]}/>
                <Eckig_Schwarz_90 scale={.04} position={[sideRight ? size.door.width/2 + .003 : size.door.width/2 - .001, -size.door.height/3 - .095, .032]} rotation={[0, Math.PI, rotationItemZ]}/>
            </group>,
            <group position={position}>
                <Rund_Schwarz_90 scale={.04} position={[sideRight ? size.door.width/2 - .01 : size.door.width/2 + .01, size.door.height/3, -.007]} rotation={[0, 0, rotationItemZ]}/>
                <Rund_Schwarz_90 scale={.04} position={[sideRight ? size.door.width/2 - .01 : size.door.width/2 + .01, -size.door.height/3 - .095, -.007]} rotation={[0, 0, rotationItemZ]}/>
            </group>,
        ][config[configName.fittings].value.optionsSelected[0].id]

    }, [size, config[configName.model].value, config[configName.fittings].value, sideRight])
}