import {useContext, useEffect, useRef, useState} from 'react'
import { useGLTF } from '@react-three/drei'
import {OptionsContext} from '../../App.js'
import {configName} from '../../helpers/constants.js'
import {getRendererOptions} from '../../helpers/functions.js'
import {useThree} from "@react-three/fiber";

const path = '/assets/models/oven/'
const models = [
    { name: 'eos_picco_w',           url: `${path}Picco_w.glb`,          size: [  .5,   .3, .215] }, // Dimensions (h, w, d):
    { name: 'eos_thermotec_w',       url: `${path}Thermotec_w.glb`,      size: [  .6, .375,   .3] },
    { name: 'eos_gracil_s',          url: `${path}Gracil_s.glb`,         size: [ .76, .505,  .25] },
    { name: 'eos_euro_max_s',        url: `${path}Euro_max_s.glb`,       size: [.925,   .5,   .5] },
    { name: 'eos_mythos_s35',        url: `${path}Mythos_s_35.glb`,      size: [ .84,  .42,  .37] },
    { name: 'eos_mythos_s55',        url: `${path}Mythos_s_55.glb`,      size: [ .84,   .6,  .37] },
    { name: 'eos_cubo_2',            url: `${path}Cubo_2.glb`,           size: [ .72,   .4, .385] },
    { name: 'eos_finnrock',          url: `${path}FinnRock.glb`,         size: [ .76, .413, .385] },

    { name: 'eos_bi_o_picco_w',      url: `${path}Bio_Picco_W.glb`,      size: [  .5,   .3, .215] },
    { name: 'eos_bi_o_tec_w',        url: `${path}Bio_Tec_W.glb`,        size: [  .6, .375,  .38] },
    { name: 'eos_bi_o_gracil_black', url: `${path}Bio_Gracil_Black.glb`, size: [ .76, .505,  .25] },
    { name: 'eos_bi_o_max',          url: `${path}Bio_Max.glb`,          size: [.925,   .5,   .5] },
    { name: 'eos_bi_o_cubo',         url: `${path}Bio_Cubo.glb`,         size: [ .72,   .4, .385] },
    { name: 'eos_bi_o_cubo_black',   url: `${path}Bio_Cubo_Black.glb`,   size: [ .72,   .4, .385] },
    { name: 'eos_herkules_s25_vapor_black', url: `${path}Herkules_S25_Vapor_Black.glb`, size: [ .75, .405, .385] },
    { name: 'eos_mythos_mit_vapor',  url: `${path}Mythos_Mit_Vapor.glb`, size: [ .84,   .6,  .37] },
]

function Model({ url }) {
    const { scene } = useGLTF(url)
    return <primitive object={scene} />
}

export default function Oven({ size }) {
    const { config, ref } = useContext( OptionsContext )
    const { gl } = useThree()

    const modelRef = useRef()
    const [ovenModel, setOvenModel] = useState(models[0])

    useEffect(() => {
        const parentName = config[configName.oven].value.optionsSelected[0].name
        const selectedOven = ['bio_ofen', 'finnischer_ofen'].includes(parentName)
            ? config[parentName].value.optionsSelected[0]
            : null
        const value = models[models.findIndex((m) => m.name === selectedOven?.name)]
        setOvenModel(value)

        if (parentName === 'finnischer_ofen' && value && value.name === 'eos_picco_w') {
            return
        }

        // Make Screenshot Model
        if (value && ref.screenSaver && ref.screenSaver.current) {
            ref.screenSaver.current.style.backgroundImage = 'url(' + gl.domElement.toDataURL() + ')'
            ref.screenSaver.current.classList.add('show')
            ref.screenSaver.current.classList.add('change_oven')
            ref.buttonSubmit.current.setAttribute('disabled', true)
            ref.buttonSubmitMob.current.setAttribute('disabled', true)
        }
    }, [config[configName.oven].value, config[configName.ovenFinnish].value, config[configName.ovenBio].value])

    // Hide Screenshot
    useEffect(() => {
        if (ref.screenSaver && ref.screenSaver.current) {
            ref.screenSaver.current.style.backgroundImage = 'url()'
            ref.screenSaver.current.classList.remove('show')
            ref.screenSaver.current.classList.remove('change_oven')
            ref.buttonSubmit.current.removeAttribute('disabled')
            ref.buttonSubmitMob.current.removeAttribute('disabled')
        }
    }, [ovenModel])

    useEffect(() => {
        if (!modelRef || !ovenModel) return

        const model = config[configName.model].value.optionsSelected[0].name
        const layout = getRendererOptions({ config, groupName: configName.benchLayout, selected: true }).name
        // const bench = config[configName.benchOption].value.optionsSelected[0].name

        const { width: sWidth, depth: sDepth } = size.model
        const width = sWidth - size.model.wallThickness * 2
        const depth = sDepth - size.model.wallThickness * 2
        const [oHeight, oWidth, oDepth] = ovenModel.size
        const sideGlassRight = size.glassElem.side === 'R'

        modelRef.current.rotation.y = Math.PI

        switch (layout) {

            // C
            case 'layout_c':
                if (model === 'athen') {
                    modelRef.current.position.set(size.model.wFront - .15, 0, depth)
                } else {
                    modelRef.current.position.set(width, 0, depth)
                }
                break

            // C-mirror
            case 'layout_c_mirrored':
                if (model === 'athen') {
                    modelRef.current.position.set(width, 0, Math.min(size.model.wRight - oWidth - .15, 1))
                    modelRef.current.rotation.y = - Math.PI / 2
                } else {
                    modelRef.current.position.set(oWidth, 0, depth)
                }
                break

            // B
            case 'layout_b':
                if (model === 'athen') {
                    modelRef.current.position.set(oWidth, 0, depth)
                } else {
                    modelRef.current.position.set(sideGlassRight ? width : oWidth, 0, depth)
                }
                break

            // D
            case 'layout_d':
                if (sWidth < 2.1) {
                    modelRef.current.rotation.y = Math.PI / 2
                    modelRef.current.position.set(sideGlassRight ? 0 : width - oDepth, 0, oWidth)
                } else {
                    modelRef.current.rotation.y = 0
                    modelRef.current.position.set(width / 2 - oWidth / 2, 0, 0)
                }
                break

            default:
                if (model === 'athen') {
                    modelRef.current.position.set(oWidth, 0, depth)
                } else {
                    modelRef.current.position.set(sideGlassRight ? width : oWidth, 0, depth)
                }


        }
    }, [ovenModel, size, config[configName.benchLayout].value, config[configName.model].value])

    return (
        <group position={[size.model.wallThickness, 0, size.model.wallThickness]}>
            <group ref={modelRef}>
                {ovenModel && ovenModel.url && <Model url={ovenModel.url}/>}
            </group>
        </group>
    )
}