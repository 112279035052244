import {useContext, useEffect, useMemo, useState} from 'react'
import {OptionsContext} from '../../App.js'
import {configName} from "../../helpers/constants.js"
import {useGLTF} from "@react-three/drei"

import {Rund_Holz} from './Rund_Holz.jsx'
import {Knauf_Edelstahl} from './Knauf_Edelstahl.jsx'
import {Knauf_Schwarz_black} from './Knauf_Schwarz_black.jsx'

const models = {
    eckig_edelstahl: '/assets/models/doorHandle/Eckig_Edelstahl.glb',
    eckig_edelstahl_wood: '/assets/models/doorHandle/Eckig_Edelstahl_wood.glb',
    eckig_edelstahl_2: '/assets/models/doorHandle/Eckig_Edelstahl_2.glb',
    eckig_edelstahl_2_wood: '/assets/models/doorHandle/Eckig_Edelstahl_2_wood.glb',
    eckig_schwarz: '/assets/models/doorHandle/Eckig_Schwarz.glb',
    eckig_schwarz_wood: '/assets/models/doorHandle/Eckig_Schwarz_wood.glb',
}
Object.keys(models).forEach(name => useGLTF.preload(models[name]))

export default function DoorHandle({ size }) {
    const { config } = useContext( OptionsContext )

    const [doorHandleId, setDoorHandleId] = useState('')
    const [sideRight, setSideRight] = useState(size.glassElem.side === 'R')

    /** Door Handle ID - State */
    useEffect(() => {
        setDoorHandleId(() => config[configName.doorHandles].value.optionsSelected[0].id)
    }, [config[configName.doorHandles].value])

    /** Side - State */
    useEffect(() => {
        if (sideRight !== (size.glassElem.side === 'R')) {
            setSideRight(size.glassElem.side === 'R')
        }
    }, [size])

    return useMemo(() => {
        const position = [sideRight ? -size.door.width/2 + .08 : size.door.width/2 - .08, 0, size.door.depth/2]
        const rotation = [0, 0, Math.PI/2]
        const posXDoorHandle1 = sideRight ? -.04 : .04

        const doorHandle = [
            <group position={position} rotation={rotation}>
                <Rund_Holz scale={.04} position-z={-.0725} position-x={posXDoorHandle1} rotation={[0, -Math.PI/2, 0]}/>
                <Rund_Holz scale={.04} position-z={ .0625} position-x={posXDoorHandle1} rotation={[0,  Math.PI/2, 0]}/>
            </group>,
            <group position={position} rotation={rotation}>
                <primitive scale={.04} position-z={-.059} rotation={[0, -Math.PI / 2, Math.PI / 2]} object={useGLTF(models.eckig_edelstahl).scene}/>
                <primitive scale={.04} position-z={ .049} rotation={[0,  Math.PI / 2, Math.PI / 2]} object={useGLTF(models.eckig_edelstahl_wood).scene} />
            </group>,
            <group position={position} rotation={rotation}>
                <primitive scale={.04} position-z={-.065} rotation={[0, -Math.PI / 2, Math.PI / 2]} object={useGLTF(models.eckig_edelstahl_2).scene}/>
                <primitive scale={.04} position-z={ .048} rotation={[0,  Math.PI / 2, Math.PI / 2]} object={useGLTF(models.eckig_edelstahl_2_wood).scene} />
            </group>,
            <group position={position} rotation={rotation}>
                <primitive scale={.04} position-z={-.065} rotation={[0, -Math.PI / 2, Math.PI / 2]} object={useGLTF(models.eckig_schwarz).scene}/>
                <primitive scale={.04} position-z={ .048} rotation={[0,  Math.PI / 2, Math.PI / 2]} object={useGLTF(models.eckig_schwarz_wood).scene}/>
            </group>,
            <group position={position} rotation={rotation}>
                <Knauf_Edelstahl scale={.04} position-z={-.015} position-x={0.005} rotation={[0, 0, Math.PI / 2]}/>
                <Knauf_Edelstahl scale={.04} position-z={ .005} rotation={[0, Math.PI, Math.PI / 2]}/>
            </group>,
            <group position={position} rotation={rotation}>
                <Knauf_Schwarz_black scale={.04} position-z={-.015} position-x={0.005} rotation={[0, 0, Math.PI / 2]}/>
                <Knauf_Schwarz_black scale={.04} position-z={ .005} position-x={  0  } rotation={[0, Math.PI, Math.PI / 2]}/>
            </group>,
        ]

        return (
            <group name="door_handle">
                 { doorHandle[doorHandleId] }
            </group>


        )

    }, [size, doorHandleId, sideRight])
}