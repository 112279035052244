import React, {useContext, useEffect, useMemo} from 'react'
import {additionalOptionName, configName, modelHeight} from '../../helpers/constants.js'
import {OptionsContext} from '../../App.js'
import Venus from './Venus.js'
import Athen from './Athen.js'
import Kristall from './Kristall.js'
import Morena from './Morena.js'
import {getRendererOptions, setRepeatTexture, setVisibleWalls} from '../../helpers/functions'
import {useTexture} from '@react-three/drei'
import {useThree} from '@react-three/fiber'

export function SaunaModel() {
    const { config, setSize, tabs, ref } = useContext( OptionsContext )
    const { camera } = useThree()
    const model = config[configName.model].value.optionsSelected[0]

    /** Tabs init - set visible walls and ceiling */
    useEffect(() => {
        if (ref[model.name]) {
            if (tabs[2].checked || tabs[3].checked) {
                setVisibleWalls(camera, ref[model.name])
            } else {
                setVisibleWalls(null, ref[model.name])
            }
        }
    }, [tabs])

    /** Mouse Events - set visible walls and ceiling */
    let mouseIsDown = false
    useEffect(() => {
        function handleMouseEvents(event) {
            const mouseOverCanvas = event.target.closest('#' + ref.canvasWrapper.current.id) === ref.canvasWrapper.current

            if (event.type === 'mousedown' && mouseOverCanvas) {mouseIsDown = true}
            if (event.type === 'mouseup') {mouseIsDown = false}

            if (event.type === 'mousemove' && mouseIsDown && mouseOverCanvas && tabs[2].checked || tabs[3].checked) {
                if (ref[model.name]) {
                    setVisibleWalls(camera, ref[model.name])
                }
            }
        }

        document.addEventListener('mousemove', handleMouseEvents)
        document.addEventListener('mousedown', handleMouseEvents)
        document.addEventListener('mouseup', handleMouseEvents)

        return () => {
            document.removeEventListener('mousemove', handleMouseEvents)
            document.removeEventListener('mousedown', handleMouseEvents)
            document.removeEventListener('mouseup', handleMouseEvents)
        };
    }, [model])

    // Size
    const size = useMemo(() => {
        const glassElement = getRendererOptions({ config, groupName: configName.glassElem, selected: true })
        let side = glassElement?.additional_options.options.find(o => o.selected).name

        const size = {
            glassElem: {
                name: glassElement?.name,
                side: side === additionalOptionName.left ? 'L' : 'R',
            },
            model: {
                height: modelHeight,
                wallThickness: .08, // wallThickness === partition --- для удобства рендера
                distance: .08, // Vertical board (distance)
                width: config[configName.size].value.width,
                depth: config[configName.size].value.depth,
            },
            door: {
                width: .62,
                height: 1.8,
                depth: .01,
                clearance: .005,
            },
            window: {
                height: .9,
                depth: .01,
                minWidth: .4,
                offsetDepth: 0,
            }
        }

        const breakpoint = 1.7

        if (model.name === 'venus') {
            const widthWindow = size.model.width - size.door.width - size.model.wallThickness * 2 - size.model.distance

            if (size.glassElem.name === 'bodentiefes') {
                size.window.w1 = size.window.w2 = {
                    width: size.model.width >= breakpoint ? (widthWindow + size.model.distance) / 2 : widthWindow + size.model.distance,
                    height: size.door.height
                }
            } else {
                size.window.w1 = size.window.w2 = {
                    width: size.model.width >= breakpoint ? (widthWindow - size.model.distance) / 2 : widthWindow,
                    height: size.window.height
                }
            }
        }

        if (model.name === 'morena') {
            size.window.w1 = {
                width: size.model.width >= breakpoint
                    ? (size.model.width - size.door.width) / 2 + .0001
                    : size.model.width - size.door.width - size.model.wallThickness + .0001,
                height: size.door.height
            }
            size.window.w1.widthGlass = size.window.w1.width - size.model.wallThickness / 2 + size.door.depth / 2

            size.window.w2 = {
                width: size.model.depth > breakpoint
                    ? (size.model.depth - size.model.wallThickness * 3) / 3 + size.model.wallThickness * .67
                    : (size.model.depth - size.model.wallThickness * 3) / 2 + size.model.wallThickness,
                height: size.door.height
            }
            size.window.w2.widthGlass = size.window.w2.width - size.model.wallThickness / 2 - size.door.depth / 2
        }

        setSize(size)
        return size
    }, [config[configName.size].value, config[configName.model].value, config[configName.glassElem].value])

    // Textures
    const floor_texture = useTexture('/assets/textures/floor.jpg')
    setRepeatTexture(floor_texture, size.model.width, size.model.depth)

    const namesFiles = ['keine_verkleidung', 'platte', 'profilholz', 'stabchen']
    const countDoubleEveryNameFiles = [2, 3, 3, 3]
    const imageUrls = []

    for (let countTexture = 0; countTexture < 12; countTexture++) {
        const innerArray = []
        for (let i = 0; i < namesFiles.length; i++) {
            for (let j = 0; j < countDoubleEveryNameFiles[i]; j++) {
                innerArray.push(`/assets/textures/${countTexture + 1}_${namesFiles[i]}.jpg`)
            }
        }
        imageUrls.push(innerArray)
    }

    const typeWood = config[configName.typeWood].value.optionsSelected[0]
    const typeWood2 = config[configName.typeWood2].value.optionsSelected[0]
    const construction = config[configName.construction].value.optionsSelected[0]
    const construction2 = config[configName.construction2].value.optionsSelected[0]

    /** Встановити властивості текстур */
    function setTextureProperties(textures) {
        for (const k in textures) {

            // ver, hor
            if (['0', '1'].includes(k)) {
                setRepeatTexture(textures[k], 1, 1, 1, .5, !0)
                // hor
                if (k === '1') textures[k].rotation = Math.PI / 2
            }

            // width -- platte, profilholz, stabchen
            if (['2', '5', '8'].includes(k)) {
                setRepeatTexture(textures[k], size.model.width, size.model.height, 2.7, .8, !0)
            }

            // depth -- platte, profilholz, stabchen
            if (['3', '6', '9'].includes(k)) {
                setRepeatTexture(textures[k], size.model.depth, size.model.height, 2.7, .8, !0)
            }

            // ceiling -- platte, profilholz, stabchen
            if (['4', '7', '10'].includes(k)) {
                setRepeatTexture(textures[k], size.model.depth, size.model.width, 2.7, .8, !0)
                textures[k].rotation = Math.PI / 2
            }
        }
    }

    const getTextureIndex = name => {
        return {
            'fichte': 0,
            'zirbe': 1,
            'erle': 2,
            'espe': 3,
            'termo_erle': 4,
            'termo_espe': 5,
            'eiche_rissig': 6,
            'eiche_altholz': 7,
            'hemlock': 8,
            'structur1': 9,
            'structur2': 10,
            'structur3': 11,
        }[name]
    }

    const textures = useTexture(imageUrls[getTextureIndex(typeWood.name)])
    const textures2 = useTexture(imageUrls[getTextureIndex(typeWood2.name)])
    setTextureProperties(textures)
    setTextureProperties(textures2)


    /** Встановити обєкт з вибраною текстурою */
    const texture = {
        floor: floor_texture,
        out: {
            wall: {
                w: construction.name === 'platte' ? textures[2] : construction.name === 'stabchen' ? textures[8] : textures[5],
                d: construction.name === 'platte' ? textures[3] : construction.name === 'stabchen' ? textures[9] : textures[6]
            },
            ceiling: textures[7],
            bar: {ver: textures[0], hor: textures[1]}
        },
        in:  {
            wall: {
                w: construction2.name === 'platte' ? textures2[2] : textures2[5],
                d: construction2.name === 'platte' ? textures2[3] : textures2[6]
            },
            ceiling: construction2.name === 'platte' ? textures2[4] : textures2[7],
            bar: {ver: textures2[0], hor: textures2[1]}
        }
    }

    const saunaModel = [
        <Venus size={size} texture={texture} />,
        <Athen size={size} texture={texture}/>,
        <Kristall size={size} texture={texture}/>,
        <Morena size={size} texture={texture}/>
    ][config[configName.model].value.optionsSelected[0].id]

    return (
        <group rotation-y={- Math.PI / 2} position-z={.04}>
            { saunaModel }
        </group>
    )
}
