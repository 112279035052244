import React, {useContext, useEffect, useRef, useState} from 'react'
import axios from 'axios'
import {OptionsContext} from '../../../App.js'
import {configName} from '../../../helpers/constants.js'
import {getRendererOptions} from '../../../helpers/functions.js'
import {Icon} from '../../icon'

const devMode = process.env.NODE_ENV === 'development'
const ajaxUrl = devMode ? process.env.REACT_APP_AJAX_URL_DEV : process.env.REACT_APP_AJAX_URL

export default function ContactForm({ contactFormRef }) {
    const { config, tabs, setTabs, totalPrice, screenshot, setShowOptionPanel } = useContext( OptionsContext )

    const [formFilled, setFormFilled] = useState(true)
    const spinnerRef = useRef()
    const informationRef = useRef()
    const informationTextRef = useRef()

    const defaultFormValues = {
        name: '', surname: '', tel: '', email: '', plz: '', checkbox1: false, checkbox2: false
    }

    if (devMode) {
        defaultFormValues.tel = 1234567890
        defaultFormValues.email = 'favorstreet21@gmail.com'
        defaultFormValues.plz = 54321
        defaultFormValues.checkbox2 = true
    }

    const [formData, setFormData] = useState(defaultFormValues)

    /**
     * Validate fields
     */
    useEffect(() => {
        setFormFilled(true)

        Object.keys(formData).forEach(key => {
            const value = formData[key]
            if (value && contactFormRef) {
                const input =
                    contactFormRef.querySelector('input[name='+ key +']')
                input.classList.remove('error')
            } else {
                // if (key !== 'name' && key !== 'surname' && key !== 'checkbox1') {
                //     setFormFilled(false)
                // }
            }
        })
    }, [formData])

    /**
     * Setting Tab
     */
    function setTab() {
        const prevChecked = tabs.find(tab => tab.prevChecked)
        const newTabs = tabs.map((t, i) => {
            t.checked = t.id === prevChecked.id
            return t
        })
        setTabs( newTabs )
    }

    /**
     * Hide Form
     */
    function handleCloseContactForm() {
        setTab()
        setShowOptionPanel(true)
        contactFormRef.style.display = 'none'
    }

    /**
     * Ajax
     */
    function handleSubmit(e) {
        e.preventDefault()

        spinnerRef.current.style.display = 'block'

        /**
         * Ajax request
         */
        function ajaxRequest(data) {

            console.log( 'ajax url:', ajaxUrl )

            axios({
                url: ajaxUrl,
                method: 'post',
                params: {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Content-Length': JSON.stringify(data).length
                    },
                },
                data: JSON.stringify(data)
            })
                .then(function(response){
                    spinnerRef.current.style.display = 'none'
                    //console.log(' | data |',  response.data )

                    /**
                     * Обробка незаповнення полів форми
                     */
                    if (response.data && response.data.status && response.data.status === 'error_empty_form_fields') {
                        if (response.data.fields) {
                            Object.keys(response.data.fields).forEach(key => {
                                const nameField = response.data.fields[key]
                                const input =
                                    contactFormRef.querySelector('input[name='+ nameField +']')
                                input.classList.add('error')
                                setFormData({
                                    ...formData,
                                    [nameField]: ['checkbox1', 'checkbox2'].includes(nameField) ? false : ''
                                })
                            })
                        }

                        return
                    }

                    /**
                     * Ok or Error Status Send Mail
                     */
                    const separatorStr = 'ScecUjcgjlm=>Yfpfd;lb'

                    if (response.data && typeof response.data === 'string' && response.data.includes(separatorStr)) {

                        const json = response.data.split(separatorStr)?.[1]
                        const data = json ? JSON.parse(json) : null

                        if (data) {

                            if (data.status_mail === 'ok') {

                                informationTextRef.current.innerText = data.message_mail
                                informationRef.current.style.backgroundColor = '#63c753'
                                informationRef.current.classList.add('show')

                                setTimeout(() => {

                                    document.cookie="file=" + data.file_url + ";path=/";
                                    window.open(data.file_url, '_blank')
                                    // window.open(data.file_url, "_blank", "toolbar=1, scrollbars=1, resizable=1, width=" + 1015 + ", height=" + 800);
                                    contactFormRef.style.display = 'none'
                                    informationRef.current.classList.remove('show')
                                    setTab()
                                    setShowOptionPanel(true)
                                    if (!devMode) {
                                        setFormData(defaultFormValues)
                                    }

                                }, 800)

                            } else {

                                informationTextRef.current.innerText = data.message_mail
                                informationRef.current.style.backgroundColor = '#98261c'
                                informationRef.current.classList.add('show')

                            }

                        } else {

                            informationTextRef.current.innerText = 'Server data Error.'
                            informationRef.current.style.backgroundColor = '#98261c'
                            informationRef.current.classList.add('show')

                        }

                    }

                })
                .catch(error => {
                    spinnerRef.current.style.display = 'none'
                    console.log(' | error |',  error )

                    informationRef.current.classList.add('show')
                    informationRef.current.style.backgroundColor = '#98261c'
                    informationTextRef.current.innerText = 'Something went wrong.'
                    console.log('Something went wrong.');
                })
        }

        /**
         * Create Screenshot
         */
        let images
        screenshot.create(result => (images = result))

        const startTime = Date.now()

        /**
         * Follow when the screenshots come
         */
        const interval = setInterval(() => {
            if (Date.now() - startTime > 5000) {
                console.log( 'Timeout for taking screenshots.' )
                clearInterval(interval)
                spinnerRef.current.style.display = 'none'
                contactFormRef.style.display = 'none'
                informationRef.current.classList.remove('show')
                setShowOptionPanel(true)
            }

            if (images && images.length > 0) {
                clearInterval(interval)

                function getArrayOptionsSortTabs(config) {
                    const options = Object.keys(config).map(key => config[key].value)

                    const arrayOptionsSortTabs = []

                    options
                        .map(option => {

                            if (option.name === configName.size) {

                                option.options = Object.keys(option.options).map(key => option.options[key])
                                option.options[0].value = config[configName.size].value.options.width
                                option.options[1].value = config[configName.size].value.options.depth
                                return option

                            } else if (option.name === configName.lamp) {

                                return option

                            } else {

                                const result = {...option}

                                const optionsSelected = getRendererOptions({ config, groupName: option.name })
                                    .find(o => option.optionsSelected.find(os => os.name === o.name && os.id === o.id))

                                if (optionsSelected) {
                                    result.optionsSelected = optionsSelected
                                    return result
                                }

                            }

                        })
                        .filter(o => o)
                        .map(o => {
                            const obj = {id: o.id, name: o.name, tab: o.tab, title: o.title}
                            if (o.name === configName.size) {
                                obj.value = [
                                    {title: o.options[0].title, val: o[o.options[0].name]},
                                    {title: o.options[1].title, val: o[o.options[1].name]}
                                ]
                            } else if (Array.isArray(o.optionsSelected)) {
                                obj.value = o.optionsSelected.map(el => ({title: el.title}))
                            } else {
                                obj.value = {title: o.optionsSelected.title}
                            }
                            return obj
                        })
                        .forEach(o => {
                            if (!arrayOptionsSortTabs[o.tab]) arrayOptionsSortTabs[o.tab] = []
                            arrayOptionsSortTabs[o.tab].push(o)
                        })

                    return arrayOptionsSortTabs
                }

                const data = {
                    options: getArrayOptionsSortTabs(config),
                    images: images,
                    totalPrice: totalPrice,
                    tabs: tabs,
                    contacts: formData,
                    word: 'jlJLjl56*&^jkk',
                }

                /**
                 * Send an Ajax request
                 */
                ajaxRequest(data)
            }
        }, 500)
    }

    return (
        <div className="contact_form__wrapper">
            <button className="contact_form__close_btn" onClick={handleCloseContactForm}>
                <Icon icon="close" className="contact_form__close_icon close_icon" />
            </button>
            <div className="contact_form__spinner" ref={spinnerRef}>
                <Icon icon="spinner" className="spinner" />
            </div>
            <div className="contact_title">
                HERZLICHEN GLÜCKWUNSCH SIE HABEN IHRE SAUNA KONFIGURIERT!
            </div>
            <div className="contact_desc">
                Wir senden Ihnen Ihre Konfiguration inklusive Preis per E-Mail. Bitte geben Sie Ihre E-Mail Adresse ein:
            </div>
            <form className="contact_form__form" onSubmit={handleSubmit}>
                <div className="contact_form__input">
                    <input
                        type="text"
                        name="name"
                        placeholder="Vorname"
                        value={formData.name}
                        onChange={e => setFormData({...formData, name: e.target.value})}
                    />
                </div>
                <div className="contact_form__input">
                    <input
                        type="text"
                        name="surname"
                        placeholder="Nachname"
                        value={formData.surname}
                        onChange={e => setFormData({...formData, surname: e.target.value})}
                    />
                </div>
                <div className="contact_form__input">
                    <input
                        type="text"
                        name="tel"
                        // required={true}
                        placeholder="Telefonnummer *"
                        value={formData.tel}
                        onChange={e => setFormData({...formData, tel: e.target.value})}
                    />
                </div>
                <div className="contact_form__input">
                    <input
                        type="email"
                        name="email"
                        // required={true}
                        placeholder="E-Mail Adresses *"
                        value={formData.email}
                        onChange={e => setFormData({...formData, email: e.target.value})}
                    />
                </div>
                <div className="contact_form__input">
                    <input
                        type="text"
                        name="plz"
                        // required={true}
                        placeholder="PLZ *"
                        value={formData.plz}
                        onChange={e => setFormData({...formData, plz: e.target.value})}
                    />
                </div>
                <div className="contact_form__input contact_form__input--submit">
                    <input
                        type="submit"
                        value="KONFIGURATION ZUSENDEN"
                        disabled={!formFilled}
                    />
                </div>
                <div className="contact_form__checkbox">
                    <label>
                        <input
                            type="checkbox"
                            name="checkbox1"
                            checked={formData.checkbox1}
                            onChange={e => setFormData({...formData, checkbox1: e.target.checked})}
                        />
                        <span>Newsletter-Anmeldung</span>
                    </label>
                </div>
                <div className="contact_form__checkbox">
                    <label>
                        <input
                            type="checkbox"
                            name="checkbox2"
                            // required={true}
                            checked={formData.checkbox2}
                            onChange={e => setFormData({...formData, checkbox2: e.target.checked})}
                        />
                        <span>
                            Ich bestätige hiermit, dass ich die Datenschutzerklärung von
                            Polarholz gelesen habe und damit einverstanden bin, dass meine Mail-Adresse
                            für die Zusendung der Konfiguration und die Kontaktaufnahme durch unseren
                            Verkäufer verwendet werden darf.
                        </span>
                    </label>
                </div>
            </form>

            <div className="contact_form__info" ref={informationRef}>
                <Icon icon="close2" className="close_icon" onClick={() => informationRef.current.classList.remove('show')}/>
                {/*<div className="info_text">Please check your internet connection.</div>*/}
                <div className="info_text" ref={informationTextRef}>
                    Invalid address:  (to): malavkamail100@gmailcom<br />
                    Message could not be sent. Mailer Error: Invalid address:  (to): malavkamail100@gmailcom
                </div>
            </div>
        </div>
    )
}